<template>
    <template v-if="displayBreadCrumb">
        <div class="gibs-breadcrumb-body">
            <div id="gibs-breadcrumb" class="gibs-breadcrumb">
                <template v-for="step in breadCrumbSteps">
                    <div class="gibs-breadcrumb-step-wrapper">
                        <NuxtLink :to="breadcrumbStepNavigate(step.URL)" class="gibs-breadcrumb-step">
                            {{ step.Name }}
                        </NuxtLink>
                        <NuxtIcon class="gibs-breadcrumb-forward-icon" name="buttons/forward-arrow" />
                    </div>
                </template>
            </div>
        </div>
    </template>
    <!-- Breadcrumb is hidden on homepage -->
    <template v-else-if="(!displayBreadCrumb && breadCrumbSteps.length === 1) && (breadCrumbSteps[0].URL !== path)">
        <div class="w-full h-7.5"></div>
    </template>
</template>

<script setup lang="ts">
    import { z } from 'zod';
    import { breadcrumbStepNavigate} from '~/utils/url';

    const props = defineProps({
        fullPath: {
            type: String,
            default: "/"
        }
    });

    const displayBreadCrumb = ref<boolean>(false);
    const path = ref<string>(props.fullPath.toLowerCase());
    const breadCrumbSteps = ref<Array<ISiteMapEntry>>([]);

    

    function setupDisplayBreadCrumbElement() {
        displayBreadCrumb.value = breadCrumbSteps.value.length === 1? false : true;
    }


    function createBreadcrumbSteps(siteMapEntry:ISiteMapEntry): Array<ISiteMapEntry> {
        let breadCrumbSteps = [];

        if (siteMapEntry != null && siteMapEntry != 'null') {
                siteMapEntry.Pages.forEach((entry:  z.infer<typeof SiteMapItemSchema>) => {
                    breadCrumbSteps.push(entry);
                });
                breadCrumbSteps.push(siteMapEntry);
        }
        return breadCrumbSteps;
    }


    onMounted(async() => {

        breadCrumbSteps.value = [];
        const sitemapJson = retrieveSitemapData();

        if (sitemapJson != null) {
            const siteMap =  new SiteMap(sitemapJson);
            const  siteMapEntry: ISiteMapEntry = siteMap.getEntryByUrl(props.fullPath);

            breadCrumbSteps.value = createBreadcrumbSteps(siteMapEntry);
        }
      
        setupDisplayBreadCrumbElement();
    })

    watch(() => props.fullPath, async newPath => {

        const sitemapJson = retrieveSitemapData();

        if (sitemapJson != null) {
            const siteMap =  new SiteMap(sitemapJson);
            const  siteMapEntry: ISiteMapEntry = siteMap.getEntryByUrl(props.fullPath);

            breadCrumbSteps.value = createBreadcrumbSteps(siteMapEntry);
        }

        setupDisplayBreadCrumbElement();
    });
</script>